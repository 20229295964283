






































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { promiseHelper } from '@/helpers/promise-helper'

@Observer
@Component
export default class extends Vue {
  @Prop({ default: 'TBA' }) address
  @Prop({ default: false }) alignRight
  @Prop({ default: 14 }) iconSize
  @Prop({ default: '' }) iconColor
  @Prop({ default: false }) isShortAddress
  @Prop({ default: 6 }) shortStartAmount
  @Prop({ default: 10 }) shortEndAmount
  @Prop({ default: 0 }) index
  @Prop({ default: true }) isVisibleValue
  isCopied = false
  mouseoverEvent = new Event('mouseleave')
  async copyAddressMobile() {
    navigator.clipboard.writeText(this.address)
    this.isCopied = true
    await promiseHelper.delay(3000)
    if (this.tooltipElement) this.tooltipElement.dispatchEvent(this.mouseoverEvent)
    this.isCopied = false
  }
  async copyAddressDesktop() {
    navigator.clipboard.writeText(this.address)
    this.isCopied = true
    await promiseHelper.delay(3000)
    this.isCopied = false
  }
  onMouseLeave() {
    this.isCopied = false
  }
  tooltipElement
  mounted() {
    const tooltipElements = document.getElementsByClassName(`tooltipElement${this.index}`) || []
    this.tooltipElement = tooltipElements.length > 0 ? tooltipElements[0] : null
  }
}
